import Layout from "antd/lib/layout/layout"
import React, { useState } from "react"
import LandingPageHeader from "../components/LandingPage/LandingPageHeader"
import LandingPageFooter from "../components/LandingPage/LandingPageFooter"
import SEO from "../shared/Components/Seo"
import "../shared/styles/policies.less"

const TermsOfUse = () => {
  const [clickedFreeTrial, setClickedFreeTrial] = useState(false)
  return (
    <>
      {" "}
      <SEO title="Privacy Policy" />
      <Layout>
        <LandingPageHeader
          clickedFreeTrial={clickedFreeTrial}
          setClickedFreeTrial={setClickedFreeTrial}
        />
        <main style={{ marginTop: 106 }}>
          <div className="container">
            <div className="container__header">
              <h1>Terms of use</h1>
            </div>
            <div className="container__content">
              <div>
                <p>
                  <strong>Last Updated: February 17, 2022</strong>
                </p>
                <p>
                  <strong>
                    Please read these terms and conditions carefully before
                    using Our Service.
                  </strong>
                </p>
                <h2>Interpretation and Definitions</h2>
                <h3>Interpretation</h3>
                <p>
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
                <h3>Definitions</h3>
                <p>For the purposes of these Terms and Conditions:</p>
                <ol>
                  <li>
                    <p>
                      <strong>Affiliate</strong> means an entity that controls,
                      is controlled by or is under common control with a party,
                      where "control" means ownership of 50% or more of the
                      shares, equity interest, or other securities entitled to
                      vote for election of directors or other managing
                      authority.
                    </p>
                    <p>
                      <strong>Account </strong> means a unique account created
                      for you to access our Service or parts of our Service.
                    </p>
                    <p>
                      <strong>Company</strong> (referred to as either "the
                      Company", "We", "Us", or "Our" in this Agreement) refers
                      to CPA Soft LLC, D/B/A ElephantCPA, Totowa, NJ 07512.
                    </p>
                    <p>
                      <strong>Content </strong>
                      refers to content such as text, images, or other
                      information that can be posted, uploaded, linked to or
                      otherwise made available by you, regardless of the form of
                      that content.
                    </p>
                    <p>
                      <strong>Device</strong> means any device that can access
                      the Service such as but not limited to a computer, a
                      mobile phone, or a digital tablet.
                    </p>
                    <p>
                      <strong>Feedback </strong> means feedback, innovations or
                      suggestions sent by you regarding the attributes,
                      performance, or features of our Service.
                    </p>
                    <p>
                      <strong>Free Trial </strong> refers to access to our
                      Service for a limited period of time that we may provide
                      to you without charge, including access before an initial
                      Subscription purchase, or as part of an initial
                      Subscription purchase.
                    </p>
                    <p>
                      <strong>Goods </strong>refer to the items offered for sale
                      on the Service.
                    </p>
                    <p>
                      <strong>Orders </strong> mean a request by you to purchase
                      Goods from Us.
                    </p>
                    <p>
                      <strong>Personal Information </strong>refers to any
                      information you may provide to us, or that we collect or
                      deduce about you, that identifies, or could identify, who
                      you are. This may include but is not limited to your name,
                      address, date of birth, gender, email address, internet
                      protocol (IP) address, browser and other signaling
                      information, social security number, and driver’s license
                      or identification (ID) number.
                    </p>
                    <p>
                      <strong>Promotions</strong> refer to contests,
                      sweepstakes, or other promotions offered through the
                      Service.
                    </p>
                    <p>
                      <strong>Service</strong> refers to any functionality
                      and/or product that We may make available or provide to
                      you.
                    </p>
                    <p>
                      <strong>Subscriptions </strong> refer to the services or
                      access to the Service offered on a subscription basis by
                      the Company to you.
                    </p>
                    <p>
                      <strong>Terms and Conditions </strong> (also referred as
                      "Terms") mean the Terms and Conditions stated here that
                      form the entire agreement between you and the Company
                      regarding the use of the Service.
                    </p>
                    <p>
                      <strong>Third-party Service </strong>means any services or
                      content (including data, information, products or
                      services) provided by a third-party that may be displayed,
                      included, used by, or made available by the Service.
                    </p>
                    <p>
                      <strong>Users </strong>refers collectively to anyone who
                      has access to the Service via an approved, secure login
                      account. This includes the following:
                      <ol>
                        <li>
                          <p>
                            <strong>Firm User </strong> refers to the accounts
                            created as a user of the Service and under the
                            primary Subscription of the Firm, to have access to,
                            and to use the Service. This includes both active
                            Firm Users who are granted permission to access the
                            Service and inactive Firm Users whose permission to
                            access the Service has been temporarily or
                            permanently revoked.
                            <br></br>
                            <strong>Firm Clients</strong> refers to the clients
                            of the Firm who have access to and use the Service.
                          </p>
                        </li>
                      </ol>
                    </p>
                    <p>
                      <strong>Firm</strong> refers to the company or other legal
                      entity on behalf of which the User is accessing or using
                      the Service.
                    </p>
                    <p>
                      <strong>Website </strong> refers to the ElephantCPA
                      website, accessible from http://www.elephantcpa.com.
                    </p>
                  </li>
                </ol>
                <h2>Acknowledgment</h2>
                <p>
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between you and the
                  Company. These Terms and Conditions set out the rights and
                  obligations of all users regarding the use of the Service.
                </p>
                <p>
                  Your access to and use of the Service is conditioned on your
                  acceptance of and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users and
                  others who access or use the Service.
                </p>
                <p>
                  By accessing or using the Service you agree to be bound by
                  these Terms and Conditions. If you disagree with any part of
                  these Terms and Conditions then you may not access the
                  Service.
                </p>
                <p>
                  You represent that you are over the age of 18. Through this
                  agreement, the Company does not grant permission to those
                  under 18 from using the Service.
                </p>
                <p>
                  Your access to and use of the Service is also conditioned on
                  your acceptance of and compliance with the Privacy Policy of
                  the Company. Our Privacy Policy describes Our policies and
                  procedures on the collection, use, and disclosure of your
                  personal information when you use the Service or the Website
                  and informs you regarding your privacy rights and how the law
                  protects you. Please read Our Privacy Policy carefully before
                  using purchasing or using Our Goods and Services.
                </p>
                <h2>Placing Orders for Goods</h2>
                <p>
                  By placing an Order for Goods offered by the Company, you
                  warrant that you are legally capable of entering into binding
                  contracts.
                </p>
                <h3>Your Information</h3>
                <p>
                  If you wish to place an Order for Goods available on the
                  Service, you may be asked to supply, and we may collect or
                  process, certain information relevant to your Order including,
                  but not limited to Personal Information. You consent to our
                  collection, use, and/or processing your Personal Information
                  for the purposes of fulfilling your Order for Goods on the
                  Service. By submitting such information, you also consent to
                  the sharing of your Personal Information, and other
                  information you provide, to payment processing third parties
                  for purposes of facilitating or fulfilling your Order.
                </p>
                <p>
                  You represent and warrant that: (i) you have the legal right
                  to use any credit or debit card(s) or other payment method(s)
                  in connection with any Order; and that (ii) the information
                  you supply to us is true, correct, and complete.
                </p>
                <h3>Goods Order Cancellation</h3>
                <p>
                  We reserve the right to refuse or cancel your Order at any
                  time for certain reasons including but not limited to:
                </p>
                <ul>
                  <li>
                    <p>Goods availability</p>
                  </li>
                  <li>
                    <p>Errors in the description or prices for Goods</p>
                  </li>
                  <li>
                    <p>Errors in your Order</p>
                  </li>
                </ul>
                <p>
                  We reserve the right to refuse or cancel your Order if fraud
                  or an unauthorized or illegal transaction is suspected.
                </p>
                <h3>Your Goods Order Cancellation Rights</h3>
                <p>
                  Any Goods you purchase can only be returned in accordance with
                  these Terms and Conditions and Our Refunds Policy.
                </p>
                <p>
                  Our Refunds Policy forms a part of these Terms and Conditions.
                  Please read our Refunds Policy terms to learn more about your
                  right to cancel your Order.
                </p>
                <h3>Availability, Errors and Inaccuracies</h3>
                <p>
                  We continually update Our offerings of Goods. The Goods
                  available on the Website may be mispriced, described
                  inaccurately, or unavailable, and We may experience delays in
                  updating information regarding our Goods on the Website and in
                  Our advertising on other websites.
                </p>
                <p>
                  We cannot and do not guarantee the accuracy or completeness of
                  any information, including prices, product images,
                  specifications, and availability. We reserve the right to
                  change or update information and to correct errors,
                  inaccuracies, or omissions at any time without prior notice.
                </p>
                <h3>Prices Policy</h3>
                <p>
                  The Company reserves the right to revise its prices at any
                  time prior to accepting an Order.
                </p>
                <p>
                  The prices quoted may be revised by the Company subsequent to
                  accepting an Order in the event of any occurrence affecting
                  delivery caused by government action or regulations, industry
                  standards, increased data storage and other operational costs,
                  and any other matter beyond the control of the Company. In
                  that event, you will have the right to cancel your Order.
                </p>
                <h3>Payments</h3>
                <p>
                  Payment can be made through various payment methods we have
                  available, such as Visa, MasterCard, American Express cards,
                  or online payment methods (Stripe, for example).
                </p>
                <p>
                  Payment cards (credit cards or debit cards) are subject to
                  validation checks and authorization by your card issuer. If we
                  do not receive the required authorization, We will not be
                  liable for any delay or non-delivery of your Order.
                </p>
                <h2>Subscriptions</h2>
                <p>
                  By signing up for Goods offered by the Company, you warrant
                  that you are legally capable of entering into binding
                  contracts.
                </p>
                <h3>Subscription Period</h3>
                <p>
                  The Service or some parts of the Service are available only
                  with a paid Subscription. You will be billed in advance on a
                  recurring and periodic basis (such as daily, weekly, monthly,
                  or annually), depending on the type of Subscription plan you
                  select when purchasing the Subscription, and as noted here.
                </p>
                <p>
                  <strong>Monthly Subscription: </strong>When you purchase a
                  Monthly Subscription Service, you will be billed monthly, and
                  in advance for the upcoming month of Service, based on your
                  number of active Firm Users. At the end of each period, your
                  Subscription will automatically renew under the exact same
                  conditions unless you or the Company cancels it.
                </p>
                <p>
                  <strong>Annual Subscription: </strong> When you purchase an
                  Annual Subscription Service, you will be billed once for a
                  12-month period, at a discounted rate, based on your desired
                  number of Firm Users. At the end of the Annual Subscription
                  period, you will have the option to purchase another Annual
                  Subscription or transition to a Monthly Subscription.
                </p>
                <h3>Subscription Cancellations and Refunds Policy</h3>
                <p>
                  Subscription cancellations vary based on the type of
                  Subscription.
                </p>
                <p>
                  <strong>Monthly Subscription Cancellation: </strong> You may
                  cancel your Monthly Subscription renewal either through your
                  Account settings page or by contacting the Company. You will
                  be able to access the Service until the end of your current
                  Subscription period. Except when required by law, Monthly
                  Subscription fees that have been paid are non-refundable.
                </p>
                <p>
                  <strong>Annual Subscription Cancellations</strong>: You may
                  cancel your Annual Subscription and request a refund for the
                  remainder of the Annual Subscription period. There will be an
                  additional cancellation fee of 25% of the total contract price
                  for cancellation of Annual Subscription contracts. All access
                  to the Service for all Firms, Firm Users and Firm Clients will
                  be terminated upon Annual Subscription cancellation.
                </p>
                <p>
                  Certain refund requests for Subscriptions may be considered by
                  the Company on a case-by-case basis and granted at the sole
                  discretion of the Company.
                </p>
                <h3>Billing</h3>
                <p>
                  You shall provide the Company with accurate and complete
                  billing information including full name, address, state, zip
                  code, telephone number, and a valid payment method.
                </p>
                <p>
                  For Subscriptions offering recurring billing (e.g., Monthly
                  Subscription Service), should automatic billing fail to occur
                  for any reason, the Subscription will be suspended until the
                  billing information is corrected on the Website.
                </p>
                <h3>Fee Changes</h3>
                <p>
                  The Company, in its sole discretion and at any time, may
                  modify the Subscription fees. Any Subscription fee change will
                  become effective at the end of the then-current Subscription
                  period.
                </p>
                <p>
                  The Company will provide you with reasonable prior notice of
                  any change in Subscription fees to give you an opportunity to
                  terminate your Subscription before such change becomes
                  effective.
                </p>
                <p>
                  Your continued use of the Service after the Subscription fee
                  change comes into effect constitutes your agreement to pay the
                  modified Subscription fee amount.
                </p>
                <h3>Free Trial</h3>
                <p>
                  The Company may, at its sole discretion, offer a Subscription
                  with a Free Trial for a limited period.
                </p>
                <p>
                  You may be required to enter your billing information in order
                  to sign up for the Free Trial. In such case, if you do enter
                  your billing information when signing up for a Free Trial, you
                  will not be charged by the Company until the Free Trial has
                  expired. On the last day of the Free Trial period, unless you
                  have cancelled your Subscription, you will be automatically
                  charged the applicable Subscription fee(s) for the type of
                  Subscription you have selected.
                </p>
                <p>
                  At any time and without notice, the Company reserves the right
                  to (i) modify the terms and conditions of the Free Trial
                  offer, or (ii) cancel such Free Trial offer.
                </p>
                <h2>Promotions</h2>
                <p>
                  Any Promotions made available through the Service may be
                  governed by rules that are separate from these Terms.
                </p>
                <p>
                  If you participate in any Promotions, please review the
                  applicable rules as well as our Privacy Policy. If the rules
                  for a Promotion conflict with these Terms, the Promotion rules
                  will apply.
                </p>
                <h2>User Accounts</h2>
                <p>
                  When you create an account with Us, you agree to provide
                  accurate, complete, and current information to Us. Failure to
                  do so constitutes a breach of these Terms, which may result in
                  immediate termination of your account on Our Service, without
                  notice.
                </p>
                <p>
                  You are responsible for safeguarding the password that you use
                  to access the Service and for any activities or actions under
                  your password, whether your password is with Our Service or a
                  Third-Party Service.
                </p>
                <p>
                  You agree not to disclose your password to another individual,
                  business, or third party. You must notify Us immediately upon
                  becoming aware of any breach of security or unauthorized use
                  of your account.
                </p>
                <p>
                  You may not use as a username the name of another person or
                  entity or one that is not lawfully available for use, a name
                  or trademark that is subject to any rights of another person
                  or entity other than you without appropriate authorization, or
                  a name that is otherwise offensive, vulgar or obscene.
                </p>
                <h2>Content</h2>
                <h3>Your Right to Post Content</h3>
                <p>
                  Our Service allows you to post Content. You are responsible
                  for the Content that you, the Firm, Firm Users, or Firm
                  Clients post to the Service, including its legality,
                  reliability, and appropriateness.
                </p>
                <p>
                  By posting Content to the Service, you grant Us the right and
                  license to display, reproduce, and distribute such Content on
                  and through the Service. You retain any and all of your rights
                  to any Content you submit, post, or display on or through the
                  Service and you are responsible for protecting those rights.
                  You agree that this license includes the right for Us to make
                  your Content available to other users of the Service, who may
                  also use your Content subject to these Terms.
                </p>
                <p>
                  You represent and warrant that the posting of your Content on
                  or through the Service does not violate the privacy rights,
                  publicity rights, copyrights, contract rights, or any other
                  rights of any person.
                </p>
                <h3>Content Restrictions</h3>
                <p>
                  The Company is not responsible for the content of the
                  Service's users. You expressly understand and agree that you
                  are solely responsible for the Content and for all activity
                  that occurs under your account, whether done so by you or any
                  third person using your account.
                </p>
                <p>
                  You may not transmit any Content that is unlawful, offensive,
                  upsetting, intended to disgust, threatening, libelous,
                  defamatory, obscene, or otherwise objectionable. Examples of
                  such objectionable Content include, but are not limited to,
                  the following:
                </p>
                <ul>
                  <li>
                    <p>Unlawful or promoting unlawful activity.</p>
                  </li>
                  <li>
                    <p>
                      Defamatory, discriminatory, or mean-spirited content,
                      including references or commentary about religion, race,
                      sexual orientation, gender, national/ethnic origin, or
                      other targeted groups.
                    </p>
                  </li>
                  <li>
                    <p>
                      Spam, machine or randomly generated, constituting
                      unauthorized or unsolicited advertising, chain letters,
                      any other form of unauthorized solicitation, or any form
                      of lottery or gambling.
                    </p>
                  </li>
                  <li>
                    <p>
                      Containing or installing any viruses, worms, malware,
                      trojan horses, or other content that is designed or
                      intended to disrupt, damage, or limit the functioning of
                      any software, hardware or telecommunications equipment or
                      to damage or obtain unauthorized access to any data or
                      other information of a third person.
                    </p>
                  </li>
                  <li>
                    <p>
                      Infringing on any proprietary rights of any party,
                      including patent, trademark, trade secret, copyright,
                      right of publicity, or other rights.
                    </p>
                  </li>
                  <li>
                    <p>
                      Impersonating any person or entity including the Company
                      and its employees or representatives.
                    </p>
                  </li>
                  <li>
                    <p> Violating the privacy of any third person.</p>
                  </li>
                  <li>
                    <p>False information and features.</p>
                  </li>
                </ul>
                <p>
                  The Company reserves the right, but not the obligation, to, in
                  its sole discretion, determine whether or not any Content is
                  appropriate and complies with these Terms, and to refuse or
                  remove Content. The Company can also limit or revoke your use
                  of the Service if you post such objectionable Content. As the
                  Company cannot control all content posted by users and/or
                  third parties on the Service, you agree to use the Service at
                  your own risk. You understand that by using the Service you
                  may be exposed to content that you may find offensive,
                  indecent, incorrect, or objectionable, and you agree that
                  under no circumstances will the Company be liable in any way
                  for any content, including any errors or omissions in any
                  content, or any loss or damage of any kind incurred as a
                  result of your use of any content.
                </p>
                <h3>Content Backups</h3>
                <p>
                  Although regular backups of Content are performed, the Company
                  does not guarantee there will be no loss or corruption of
                  data.
                </p>
                <p>
                  Corrupt or invalid backup points may be caused by, without
                  limitation, Content that is corrupted prior to being backed up
                  or that changes during the time a backup is performed.
                </p>
                <p>
                  The Company will provide support and attempt to troubleshoot
                  any known or discovered issues that may affect the backups of
                  Content. But you acknowledge that the Company has no liability
                  related to the integrity of Content or the failure to
                  successfully restore Content to a usable state.
                </p>
                <p>
                  You agree to maintain a complete and accurate copy of any
                  Content in a location independent of the Service.
                </p>
                <h2>Copyright Policy</h2>
                <h3>Intellectual Property Infringement</h3>
                <p>
                  We respect the intellectual property rights of others. It is
                  Our policy to respond to any claim that Content posted on the
                  Service infringes a copyright or other intellectual property
                  infringement of any person.
                </p>
                <p>
                  If you are a copyright owner, or authorized on behalf of one,
                  and you believe that the copyrighted work has been copied in a
                  way that constitutes copyright infringement that is taking
                  place through the Service, you must submit your notice in
                  writing to the attention of our copyright agent via email at
                  dmca@elephantcpa.com and include in your notice a detailed
                  description of the alleged infringement.
                </p>
                <p>
                  You may be held accountable for damages (including costs and
                  attorneys' fees) for misrepresenting that any Content is
                  infringing your copyright.{" "}
                </p>
                <h3>
                  DMCA Notice and DMCA Procedure for Copyright Infringement
                  Claims
                </h3>
                <p>
                  You may submit a notification pursuant to the Digital
                  Millennium Copyright Act (DMCA) by providing our Copyright
                  Agent with the following information in writing (see 17 U.S.C
                  512(c)(3) for further detail):
                </p>
                <ul>
                  <li>
                    <p>
                      An electronic or physical signature of the person
                      authorized to act on behalf of the owner of the
                      copyright's interest.
                    </p>
                  </li>
                  <li>
                    <p>
                      A description of the copyrighted work that you claim has
                      been infringed, including the URL (i.e., web page address)
                      of the location where the copyrighted work exists or a
                      copy of the copyrighted work.
                    </p>
                  </li>
                  <li>
                    <p>
                      Identification of the URL or other specific location on
                      the Service where the material that you claim is
                      infringing is located.
                    </p>
                  </li>
                  <li>
                    <p>Your address, telephone number, and email address.</p>
                  </li>
                  <li>
                    <p>
                      A statement by you that you have a good faith belief that
                      the disputed use is not authorized by the copyright owner,
                      its agent, or the law.
                    </p>
                  </li>
                  <li>
                    <p>
                      A statement by you, made under penalty of perjury, that
                      the above information in your notice is accurate and that
                      you are the copyright owner or authorized to act on the
                      copyright owner's behalf.
                    </p>
                  </li>
                </ul>
                <p>
                  You can contact our copyright agent via email at
                  dmca@elephantcpa.com. Upon receipt of a notification, the
                  Company will take whatever action, in its sole discretion, it
                  deems appropriate, including removal of the challenged content
                  from the Service.
                </p>
                <h2>Intellectual Property</h2>
                <p>
                  The Service and its original content (excluding Content
                  provided by you or other users), features, and functionality
                  are and will remain the exclusive property of the Company and
                  its licensors.
                </p>
                <p>
                  The Service is protected by copyright, trademark, and other
                  laws of both the Country and foreign countries.
                </p>
                <p>
                  Our trademarks and trade dress may not be used in connection
                  with any product or service without the prior written consent
                  of the Company.
                </p>
                <h2>Your Feedback to Us</h2>
                <p>
                  You assign all rights, title, and interest in any Feedback you
                  provide the Company. If for any reason such assignment is
                  ineffective, you agree to grant the Company a non-exclusive,
                  perpetual, irrevocable, royalty free, worldwide right and
                  license to use, reproduce, disclose, sub-license, distribute,
                  modify, and exploit such Feedback without restriction.
                </p>
                <h2>Links to Other Websites</h2>
                <p>
                  Our Service may contain links to third-party websites or
                  services that are not owned or controlled by the Company.
                </p>
                <p>
                  The Company has no control over, and assumes no responsibility
                  for, the content, privacy policies, or practices of any
                  third-party web sites or services. You further acknowledge and
                  agree that the Company shall not be responsible or liable,
                  directly or indirectly, for any damage or loss caused or
                  alleged to be caused by or in connection with the use of or
                  reliance on any such content, goods, or services available on
                  or through any such websites or services.
                </p>
                <p>
                  We strongly advise you to read the terms and conditions and
                  privacy policies of any third-party websites or services that
                  you visit.
                </p>
                <h2>Termination</h2>
                <p>
                  We may terminate or suspend your Account immediately, without
                  prior notice or liability, for any reason whatsoever,
                  including without limitation if you breach these Terms and
                  Conditions. Upon termination, your right to use the Service
                  will cease immediately.
                </p>
                <p>
                  If you wish to terminate your Account, you may simply
                  discontinue using the Service and notify Us of the
                  cancellation of your Subscription.
                </p>
                <h2>Limitation of Liability</h2>
                <p>
                  Notwithstanding any damages that you might incur, the entire
                  liability of the Company under any provision of this Terms,
                  your exclusive remedy for all of the foregoing shall be
                  limited to the amount actually paid by you through the Service
                  or $100 if you haven't purchased anything through the Service.
                </p>
                <p>
                  To the maximum extent permitted by applicable law, in no event
                  shall the Company or its suppliers be liable for any special,
                  incidental, indirect, or consequential damages whatsoever
                  (including, but not limited to, damages for loss of profits,
                  loss of data or other information, for business interruption,
                  for personal injury, loss of privacy arising out of or in any
                  way related to the use of or inability to use the Service,
                  third-party software and/or third-party hardware used with the
                  Service, or otherwise in connection with any provision of this
                  Terms), even if the Company has been advised of the
                  possibility of such damages and even if the remedy fails of
                  its essential purpose.
                </p>
                <p>
                  Some states do not allow the exclusion of implied warranties
                  or limitation of liability for incidental or consequential
                  damages, which means that some of the above limitations may
                  not apply. In these states, each party's liability will be
                  limited to the greatest extent permitted by law.
                </p>
                <h2>"AS IS" and "AS AVAILABLE" Disclaimer</h2>
                <p>
                  The Service is provided to you "AS IS" and "AS AVAILABLE" and
                  with all faults and defects without warranty of any kind. To
                  the maximum extent permitted under applicable law, the
                  Company, on its own behalf and on behalf of its Affiliates and
                  its and their respective licensors and service providers,
                  expressly disclaims all warranties, whether express, implied,
                  statutory or otherwise, with respect to the Service, including
                  all implied warranties of merchantability, fitness for a
                  particular purpose, title and non-infringement, and warranties
                  that may arise out of course of dealing, course of
                  performance, usage. or trade practice. Without limitation to
                  the foregoing, the Company provides no warranty or
                  undertaking, and makes no representation of any kind that the
                  Service will meet your requirements, achieve any intended
                  results, be compatible or work with any other software,
                  applications, systems or services, operate without
                  interruption, meet any performance or reliability standards,
                  or be error free or that any errors or defects can or will be
                  corrected.
                </p>
                <p>
                  Without limiting the foregoing, neither the Company nor any of
                  the company's provider makes any representation or warranty of
                  any kind, express or implied: (i) as to the operation or
                  availability of the Service, or the information, content, and
                  materials or products included thereon; (ii) that the Service
                  will be uninterrupted or error-free; (iii) as to the accuracy,
                  reliability, or currency of any information or content
                  provided through the Service; or (iv) that the Service, its
                  servers, the content, or e-mails sent from or on behalf of the
                  Company are free of viruses, scripts, trojan horses, worms,
                  malware, timebombs, or other harmful components.
                </p>
                <p>
                  Some jurisdictions do not allow the exclusion of certain types
                  of warranties or limitations on applicable statutory rights of
                  a consumer, so some or all of the above exclusions and
                  limitations may not apply to you. But in such a case the
                  exclusions and limitations set forth in this section shall be
                  applied to the greatest extent enforceable under applicable
                  law.
                </p>
                <h2>Governing Law</h2>
                <p>
                  The laws of the state of New Jersey, notwithstanding any
                  conflicts of law rules, shall govern these Terms and your use
                  of the Service. your use of the Application may subject you to
                  additional compliance with local, state, national, or
                  international laws or regulations.
                </p>
                <h2>Dispute Resolution</h2>
                <p>
                  If you have any concern or dispute about the Service, you
                  agree to first try to resolve the dispute informally by
                  contacting the Company.
                </p>
                <h2>For European Union (EU) Users</h2>
                <p>
                  If you are a European Union consumer, you will benefit from
                  any mandatory provisions of the law of the country in which
                  you are resident in.
                </p>
                <h2>United States Federal Government End Use Provisions</h2>
                <p>
                  If you are a U.S. federal government end user, our Service is
                  a "Commercial Item" as that term is defined at 48 C.F.R.
                  §2.101.
                </p>
                <h2>United States Legal Compliance</h2>
                <p>
                  You represent and warrant that (i) you are not located in a
                  country that is subject to the United States government
                  embargo, or that has been designated by the United States
                  government as a "terrorist supporting" country, and (ii) you
                  are not listed on any United States government list of
                  prohibited or restricted parties.
                </p>
                <h2>Severability and Waiver</h2>
                <h3>Severability</h3>
                <p>
                  If any provision of these Terms is held to be unenforceable or
                  invalid, such provision will be changed and interpreted to
                  accomplish the objectives of such provision to the greatest
                  extent possible under applicable law and the remaining
                  provisions will continue in full force and effect.
                </p>
                <h3>Waiver</h3>
                <p>
                  Except as provided herein, the failure to exercise a right or
                  to require performance of an obligation under this Terms shall
                  not affect a party's ability to exercise such right or require
                  such performance at any time thereafter nor shall be the
                  waiver of a breach constitute a waiver of any subsequent
                  breach.
                </p>
                <h2>Translation Interpretation</h2>
                <p>
                  This Terms and Conditions may have been translated if We have
                  made them available to you on our Service. You agree that the
                  original English text shall prevail in the case of a dispute.
                </p>
                <h2>Changes to These Terms and Conditions</h2>
                <p>
                  We reserve the right, at Our sole discretion, to modify or
                  replace this Terms at any time. If a revision is material We
                  will make reasonable efforts to provide at least 30 days'
                  notice prior to any new terms taking effect. What constitutes
                  a material change will be determined at Our sole discretion.
                </p>
                <p>
                  By continuing to access or use Our Service after those
                  revisions become effective, you agree to be bound by the
                  revised terms. If you do not agree to the new terms, in whole
                  or in part, please stop using the website and the Service.
                </p>
                <h2>Contact Us</h2>
                <p>
                  If you have any questions about these Terms and Conditions,
                  you can contact us
                </p>
                <ol>
                  <li>
                    <p>By email: support@elephantcpa.com</p>
                    <p>
                      By visiting this page on our website:
                      http://www.elephantcpa.com/contact
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </main>

        <LandingPageFooter />
      </Layout>
    </>
  )
}

export default TermsOfUse
